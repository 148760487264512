import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import router from '@/router';
import { useRoute } from 'vue-router';
export default {
  name: 'member_new',
  setup() {
    const upList = ref([]);
    const route = useRoute();
    // 获取上传资料列表
    const get_list = () => {
      let url = api.baseUrl + api.text + '?category=information';
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        upList.value = res.data.rows;
      });
    };
    const go = e => {
      router.push({
        name: 'member_details_m',
        params: {
          id: e
        }
      });
    };
    const fh = () => {
      router.go(-1);
    };
    get_list();
    return {
      go,
      upList,
      fh
    };
  }
};