import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { useStore } from 'vuex';
import router from '@/router';
import welfare_m from '@/views/mobile/welfare_m';
export default {
  name: 'welfare',
  components: {
    welfare_m
  },
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const userData = ref(false);
    const get_data_list = ref([]);
    // 获取信息
    const get_data = () => {
      axios({
        url: api.baseUrl + api.benefit,
        method: 'get'
      }).then(res => {
        // console.log(res);
        get_data_list.value = res.data.rows;
        console.log(get_data_list.value);
      });
    };
    const details = (e, name) => {
      if (name.indexOf("肌功能") !== -1) {
        router.push({
          name: localStorage.getItem('userData') ? 'member_welfare' : 'welfare_details',
          params: {
            id: e
          }
        });
      } else {
        router.push({
          name: 'welfare_details_brief',
          params: {
            id: e
          }
        });
      }
    };
    get_data();
    return {
      Client,
      get_data,
      get_data_list,
      details
    };
  }
};