import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import imageText from '@/components/imageText.vue';
import googleMaps from '@/components/googleMaps';
import accordion from '@/components/accordion';
import lowCode from '@/components/lowCode';
import subscription from '@/components/subscription';
import richText from '@/components/richText';
import imgSwipers from '@/components/imgSwipers';
import images from '@/components/images';
import videos from '@/components/videos';
import navigation from '@/components/navigation';
import goodsGrid from '@/components/goodsGrid';
import texts from '@/components/texts';
import hotMap from '@/components/hotMap';
import goodsScroll from '@/components/goodsScroll';
import mimo_replace from '@/components/home/mimo-replace';
import news_m from '@/views/mobile/news_m';
import axios from "axios";
import { api } from "@/api/api";
import { useStore } from 'vuex';
import router from '@/router';
const __default__ = {
  name: 'news',
  components: {
    imageText,
    googleMaps,
    accordion,
    lowCode,
    subscription,
    richText,
    imgSwipers,
    images,
    videos,
    navigation,
    goodsGrid,
    texts,
    hotMap,
    goodsScroll,
    mimo_replace,
    news_m
  },
  setup() {
    const get_data = ref([]);
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const list = ref([]);
    // 装修列表
    let url = api.baseUrl + api.webDecor + '?id=52';
    // 新闻列表
    let newLIst = api.baseUrl + api.text + '?category=information';
    axios({
      url,
      method: "get"
    }).then(res => {
      let {
        data
      } = res;
      // console.log(data);
      if (data.data.background) {
        var d = document.getElementById("app");
        d.style.background = 'url(' + data.data.background + ')';
        if (data.data.backgroundFit == 'fill') {}
        d.style.backgroundSize = data.data.backgroundFit == 'fill' ? '100% 100%' : 'auto 100%';
        d.style.backgroundPosition = 'center', d.style.backgroundRepeat = 'no-repeat';
      }
      get_data.value = JSON.parse(data.data.editConfig);
      console.log(get_data.value);
    });
    axios({
      url: newLIst,
      method: "get"
    }).then(res => {
      let {
        data
      } = res;
      console.log(data);
      list.value = data.rows;
    });
    const details = e => {
      router.push({
        name: 'details',
        params: {
          id: e
        }
      });
    };
    return {
      get_data,
      Client,
      list,
      details
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "dcd4ac86": _ctx.pointerChange
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;