import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
export default {
  name: 'welfare_details',
  setup() {
    const route = useRoute();
    const get_data = ref({});
    const pic = ref("");
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const show = () => {
      store.commit('serviceShow', true);
    };
    const domheight = ref('');
    // console.log(route.params);
    let url = api.baseUrl + api.activity + route.params.id;
    // let url = 'http://192.168.2.113:9090' + api.activity + '?id=' +  route.params.id
    axios({
      url,
      method: "get"
    }).then(res => {
      let {
        data
      } = res;
      console.log(data);
      let tmpList = [];
      pic.value = data.data.materials[0].url;
      for (let index in data.data.materials) {
        // console.log(data.data.materials[index].type.indexOf("video")==0);
        if (data.data.materials[index].type.indexOf("video") == 0) {
          tmpList.push(data.data.materials[index]);
        }
      }
      console.log(tmpList);
      // data.data.materials = tmpList;
      get_data.value = data.data;
      // get_data.value.materials = tmpList;
      // console.log(Math.round(tmpList.length / 2))
      let length = get_data.value.materials.length;
      get_data.value.materials.map(item => {
        if (item.hide) {
          length -= 1;
        }
      });
      domheight.value = Client === 0 ? Math.round((length - 1) / 2) * 65 + 'px' : 'auto;';
    });
    const open = (url, index) => {
      if (index <= 2 && !get_data.value.materials[index].lock) {
        window.open(url);
      } else {
        show();
      }
    };
    return {
      get_data,
      Client,
      open,
      pic,
      show,
      domheight
    };
  }
};