"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fmsReviewRate = exports.fmsUploadImage = void 0;
/*
 * @Description: 处理页面公共模块业务逻辑
 */
var axios_1 = require("axios");
// 图片接口上传
var fmsUploadImage = function (file, params, url) {
    if (params === void 0) { params = {}; }
    return new Promise(function (resolve, reject) {
        file.status = 'uploading'; // 文件状态标识
        var fd = new FormData();
        // 批量append
        Object.keys(params).forEach(function (key) {
            fd.append(key, params[key]);
        });
        axios_1.default.post(url || '/api/customer/uploadImage', fd, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (res) {
            if (res.data.code === '200') {
                file.status = 'done';
                file.url = res.data.data;
            }
            resolve(res === null || res === void 0 ? void 0 : res.data);
        }).catch(function (err) { return reject(err); });
    });
};
exports.fmsUploadImage = fmsUploadImage;
// 评论分数状态
var fmsReviewRate = function (rate) {
    var text = '';
    switch (rate) {
        case 1:
            text = 'Very Dissatisfied';
            break;
        case 2:
            text = 'Dissatisfied';
            break;
        case 3:
            text = 'Fair';
            break;
        case 4:
            text = 'Satisfied';
            break;
        case 5:
            text = 'Very Satisfied';
            break;
        default:
            break;
    }
    return text;
};
exports.fmsReviewRate = fmsReviewRate;
