"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.passwordSymbolTip = exports.passwordRegexp = exports.passwordContainReg = exports.passwordAllowSymbolArr = exports.createPasswordItemData = exports.createEmailItemData = exports.createFormItemData = void 0;
var _1 = require(".");
var createFormItemData = function (props) {
    return Object.assign({
        type: 'text',
        name: '',
        value: '',
        clearable: true,
        eyes: false,
        iconClass: '',
        placeholder: '',
        hasError: false,
        error: '',
        validate: function () {
            var pass = true;
            if (this.rule) {
                if (this.rule instanceof RegExp) {
                    pass = this.rule.test(this.value);
                }
                else if ((0, _1.isFunction)(this.rule)) {
                    pass = this.rule(this.value);
                }
                this.hasError = !pass;
            }
            return pass;
        }
    }, props);
};
exports.createFormItemData = createFormItemData;
var createEmailItemData = function (props) {
    return (0, exports.createFormItemData)(Object.assign({
        type: 'text',
        name: 'email',
        placeholder: 'Email Address',
        rule: function (value) {
            if (value && (0, _1.validateEmail)(value.toString())) {
                return true;
            }
            else {
                return false;
            }
        },
        error: 'Please enter a valid email address.'
    }, props));
};
exports.createEmailItemData = createEmailItemData;
var createPasswordItemData = function (props) {
    return (0, exports.createFormItemData)(Object.assign({
        type: 'password',
        name: 'password',
        eyes: true,
        placeholder: 'Password',
        rule: /^.{6,20}$/,
        error: 'Your password must contain at least 6 characters.'
    }, props));
};
exports.createPasswordItemData = createPasswordItemData;
exports.passwordAllowSymbolArr = ['~', '`', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '-', '+', '=', '{', '}', '[', ']', '|', ';', ':', ',', '<', '>', '.', '?', '/'];
// 是否有包含以下字符
exports.passwordContainReg = new RegExp("[0-9a-zA-Z".concat(exports.passwordAllowSymbolArr.join('\\'), "]+"));
// 是否只包含以下字符
exports.passwordRegexp = new RegExp("^[0-9a-zA-Z".concat(exports.passwordAllowSymbolArr.join('\\'), "]*$"));
// 非规定字符错误提示
exports.passwordSymbolTip = "Symbol not allowed. Please use these instead ".concat(exports.passwordAllowSymbolArr.join(' '));
