import axios from 'axios'
import { stringify } from 'qs'
// import { inBrowser } from '.'  服务端不支持此写法 orz
import { inBrowser } from './tools'
import { Toast } from '../components/ui'
import Cookies from 'js-cookie'
import { localStore } from '@/main'
import LRUCache from 'lru-cache'
import { cacheAdapterEnhancer } from 'axios-extensions'

const parseCookie = (cookies) => {
  let cookie = ''
  Object.keys(cookies).forEach(item => {
    cookie += item + '=' + cookies[item] + '; '
  })
  return cookie
}

const defaultAdapter = axios.defaults.adapter
const cacheCfg = new LRUCache({
  maxAge: 1000 * 120, // 有效期15s
  max: 1000 // 最大缓存数量
})

const adapterOptions = {
  enabledByDefault: false,
  cacheFlag: 'useCache',
  defaultCache: cacheCfg
}

const pointedApiDomain = Cookies.get('betaapiDomain')

const http = axios.create({
  baseURL: inBrowser ? (pointedApiDomain || '') : process.env.BASE_URL,
  timeout: inBrowser ? 60000 : 30000,
  adapter: inBrowser ? defaultAdapter : cacheAdapterEnhancer(defaultAdapter, adapterOptions),
  withCredentials: inBrowser && !!pointedApiDomain
})

// Add a request interceptor
http.interceptors.request.use(
  config => {
    config.headers.lang = Cookies.get('_gLang') || ''
    if (inBrowser) {
      config.url = (pointedApiDomain ? '' : '/api') + config.url
      config.headers.serverdomain = 'window.location.host'
      config.headers.apptype = 'www'
    } else {
      config.headers.cookie = parseCookie(localStore.state.serverData.cookies)
    }
    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  response => {
    const res = response.data
    const config = response.config

    // 处理header 各种facebookeventid
    const headers = response.headers
    Object.keys(headers).forEach(key => {
      if (key.includes('facebookeventid')) {
        res.fbEventId = headers[key]
      }
    })

    // transform code to number type
    if (res && typeof res.code !== 'number') {
      res.code = +res.code
    }
    if (res.code !== 200) {
      if (!inBrowser) {
        if (res.code === 503) {
          localStore.commit('serverData/updateForbid', true)
        }
      } else {
        res.message &&
          !config.noToast &&
          Toast({
            message: res.message
          })
      }
    }

    return res
  },
  err => {
    throw new Error(err)
  }
)
export const request = {
  get (url, params, options) {
    return http({
      method: 'get',
      url,
      params,
      ...options
    })
  },
  post (url, params, options) {
    let data = stringify(params)
    try {
      if (
        ['application/json', 'raw'].indexOf(
          options.headers['content-type'].toLowerCase()
        ) > -1
      ) {
        data = params
      }
    } catch (err) {
      // data = qs.stringify(params)
    }
    return http({
      method: 'post',
      url,
      data,
      ...options
    })
  }
}
